

  import React, { useEffect, useState } from 'react';
  import { Document, Page, Text, View, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
  import Logo from "./logo.jpg";
  import { useLocation } from 'react-router-dom';
import Options from './Options';


  export default function AdmissionPrint() {
    const styles = StyleSheet.create({
        container: {
          flex: 1,
        },
        headerRow: {
          flexDirection: 'row',
        },
        headerCell: {
          flex: 1,
          textAlign: 'center',
          fontSize:12,
          padding: 10,
          borderBottomWidth:1,
          fontWeight: 'bold',
          borderBottomColor:"grey",
          textTransform: 'uppercase',
          borderRightWidth:1,
          borderRightColor:"grey",
          
        },
    
        headerCell1: {
          flex: 1,
          textAlign: 'center',
          fontSize:12,
          padding: 10,
          borderBottomWidth:1,
          fontWeight: 'bold',
          borderBottomColor:"grey",
          textTransform: 'uppercase',
          
        },
        bodyRow: {
          fontSize:12,
          flexDirection: 'row',
          borderBottomWidth:1,
          borderBottomColor:"grey",
        },
        bodyCell: {
          flex: 1,
          fontSize:12,
          textAlign: 'left',
          borderRightWidth:1,
          borderRightColor:"grey",
          padding: 10,
        },
    
        bodyCell1: {
          flex: 1,
          fontSize:12,
          textAlign: 'left',
          padding: 10,
        },
        input: {
          flex: 1,
          borderColor: 'grey',
          fontSize:12,
          borderWidth: 1,
          padding: 5,
        },
        });
  
    const location = useLocation();
    const [data, setData] = useState(location.state.data);
  
    console.log("data", data);
    const formatDate = (date) => {
      const d = new Date(date);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    };

    
  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={{ padding: 20 }}>
        <View style={{ border: 1, borderColor: "grey", flex: 1, borderRadius: 5, padding: 0 }}>

          <View style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", gap: 50, alignItems: "center", padding: 10 }}>
            <View style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", gap: 5 }}>
              <Image src={Logo} style={{ width: 73, height: 70 }} />
              <Text style={{ fontSize: 12 }}>ESTD.2001</Text>
            </View>

            <View style={{display:"flex",flexDirection:"column",justifyContent:"center",gap:5,alignItems:"center"}}>
              <Text>
                PRATHYUSHA ENGINEERING COLLEGE
              </Text>
              <Text style={{textAlign:"center",fontSize:12}}>
               {("an autonomous institution").toUpperCase()}
              </Text>
            </View>
          </View>
          {/* <View style={{marginTop:10}}></View> */}
          <View style={{ borderTopWidth: 1, borderColor: "grey" }}></View>
          <View style={{ marginTop: 10 }}></View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={{ fontSize: 12 }}>Application Number : {data.applicationId} </Text>
              {/* <Text style={{fontSize:12}}>{data.counseledBy}</Text> */}

            </View>
            <Text style={{ fontSize: 12 }}>Date of Visit : {formatDate(data.dateOfVisit)}</Text>

          </View>
          <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
            {
              data.status === "Admitted" ?
                <Text style={{ fontSize: 12, }}>Admitted Course : {data.status === "Admitted" ? data.preferredBranch1 : ""} </Text>

                :

                <Text style={{ fontSize: 12 }}>Admission Status : Pending </Text>


            }


          </View>

          <View style={{ marginTop: 10 }}></View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
            <Text style={{ fontSize: 12 }}>Referred by : {((data.reference == "Staff") || data.reference == "Others" || data.reference == "Students" || data.reference == "Alumni" || data.reference == "Parents") ? data.referenceName : data.reference
            }


            </Text>
            <View style={{ display: "flex", flexDirection: "row", gap: 50 }}>


              {
                data.reference == "Students"
                  ?

                  data.refYear !== "" ?

                    <>

                      <Text style={{ fontSize: 12 }}>
                        Department :
                        &nbsp;
                        {
                          data.refDep
                        }
                      </Text>
                      <Text style={{ fontSize: 12 }}>
                        Year : &nbsp;
                        {
                          data.refYear
                        }
                      </Text>


                    </>

                    :
                    data.refDep !== "" &&

                    <Text style={{ fontSize: 12 }}>
                      Department :
                      {
                        data.refDep
                      }
                    </Text>
                  :
                  data.reference == "Staff"
                    ?

                    data.refDep !== "" &&

                    <Text style={{ fontSize: 12 }}>
                      Department :
                      {
                        data.refDep
                      }
                    </Text>


                    :

                    data.reference == "Alumni"
                      ?
                      data.refDep !== "" &&

                      <Text style={{ fontSize: 12 }}>
                        Department :
                        {
                          data.refDep
                        }
                      </Text>

                      : null
              }




            </View>
          </View>

          <View style={{ marginTop: 10 }}></View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10 }}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={{ fontSize: 12 }}>Counseled by : </Text>
              <Text style={{ fontSize: 12 }}>{data.counseledBy}</Text>

            </View>
          </View>

          <View style={{ marginTop: 10 }}></View>

          <View style={{ borderTopWidth: 1, borderColor: "grey", }}></View>
          <View style={{ marginTop: 10 }}></View>

          <Text style={{ fontSize: 15, textAlign: 'center' }}>ADMISSION REGISTRATION FORM</Text>
          <View style={{ marginTop: 10 }}></View>

          <View style={{ borderTopWidth: 1, borderColor: "grey", }}></View>
          <View style={{ marginTop: 10 }}></View>

          <View style={{ borderTop: 1, borderColor: "grey", display: "flex", padding: 0, flexDirection: "row" }}>
            <View style={{ borderRightWidth: 1, borderColor: "grey", padding: 10, width: "50%" }}>
              <Text style={{ fontSize: 13, textAlign: "center" }}>PERSONAL DETAILS</Text>
            </View>
            <View style={{ padding: 10, width: "50%" }}>
              <Text style={{ fontSize: 13, textAlign: "center" }}>EDUCATION DETAILS</Text>
            </View>

          </View>

          <View>
            <View style={{ borderTop: 1, borderColor: "grey", display: "flex", padding: 0, borderBottomWidth: 1, flexDirection: "row", flexWrap: "wrap", }}>
              <View style={{ borderRightWidth: 1, borderColor: "grey", padding: 0, width: "25%", display: "flex", gap: 10, alignItems: "flex-start", }}>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Name of the Candidate</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Prefered Branch 1</Text>

{
  data.course === "UNDER GRADUATE"
  &&
  <>
   <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Prefered Branch 2</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Prefered Branch 3</Text>

  </>
}
               
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Parent / Guardian Name</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Address</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Boarding Point / Hostel</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Father / Guardian Occupation</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Email ID</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Phone Number</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>BreakFast</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>D.O.B</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Gender</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Community</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Aadhar Number</Text>





              </View>
              <View style={{ borderRightWidth: 1, borderColor: "grey", padding: 0, width: "25%", display: "flex", gap: 10, alignItems: "flex-start", }}>

                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.candidateName}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.preferredBranch1}</Text>

                {
  data.course === "UNDER GRADUATE"
  &&
  <>
      <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.preferredBranch2}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.preferredBranch3}</Text>

  </>
}
    
             
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.guardianName}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.address}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.boardingPoint}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.guardianOccupation}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.email}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.mobileNumber}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.breakFast}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.dob}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.gender}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.community}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.aadharNumber}</Text>


              </View>

              <View style={{ borderRightWidth: 1, borderColor: "grey", padding: 0, width: "25%", display: "flex", gap: 10, alignItems: "flex-start", }}>

                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>10th School Name</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>10th Total Marks</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>10th Board </Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>10th Medium of Study</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>First Graduate</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>12th Exam Reg N.O  </Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Total Marks</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>12th Board </Text>

                
                  {
                    data.course === "UNDER GRADUATE"
                    &&
                    <>

                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>12th Medium of Study</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>12th School Name </Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Mathematics Marks</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Physics Marks</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Chemistry Marks</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Cut off Marks</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>Fourth Subject</Text>

                    </>
                }
{
  data.course === "POST GRADUATE"
  &&
  <>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>UG College Name</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>UG Department</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>UG CGPA / Percentage </Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>UG Year of Passing</Text>
  </>
}

              </View>


              <View style={{ borderRightWidth: 1, borderColor: "grey", padding: 0, width: "25%", display: "flex", gap: 10, alignItems: "flex-start", }}>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.tenthSchoolName}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.tenthTotalMarks}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.tenthBoard}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.tenthMedium}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.isFirstGraduate}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.twelfthExamRegNo}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.twelfthTotalMarks}</Text>
                <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.twelfthBoard}</Text>

{
  data.course === "UNDER GRADUATE"
  &&
  <>
    <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.twelfthMedium}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.twelfthSchoolAddress}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.mathematicsMarks}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.physicsMarks}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.chemistryMarks}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}> {data.cutOffMarks}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.fourthSubject}</Text>

  </>

}

{
  data.course === "POST GRADUATE"
  &&
  <>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.ugCollegeName}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.UgDepartment}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.ugcgpa}</Text>
  <Text style={{ fontSize: 12, textAlign: "left", flex: 1, width: "100%", minHeight: 40, maxHeight: 40, borderBottomWidth: 1, borderBottomColor: "grey" }}>{data.ugYearofPassing}</Text>
  
  </>
}
               

              </View>



              <View style={{ width: 1000 }}>

                <View style={{ marginTop: 10 }}></View>
                <View style={{ marginTop: 10 }}></View>
                <View style={{ marginTop: 10 }}></View>


                <Text style={{ fontSize: 15, textAlign: 'center' }}>FEES DETAILS</Text>
                <View style={{ marginTop: 10 }}></View>

                <View style={{ borderTopWidth: 1, borderColor: "grey", }}></View>
              </View>




              <View style={styles.container}>
                <View style={styles.headerRow}>
                  <Text style={styles.headerCell}>Particulars</Text>

                

                      <Text style={styles.headerCell1}>FEES</Text>


                  
                </View>
                {/* <View style={styles.bodyRow}>

                  
                  <Text style={styles.bodyCell}>Tuition Fees</Text>


                  {
                    data.course === "UNDER GRADUATE"?

                    data.apRtn === "TN" ?
                      data.mqRdq === 'GQ' ?

                        <Text style={styles.headerCell}>{data.GQTuitionFee}</Text>
                        :
                        <Text style={styles.headerCell1}>{data.MQTuitionFee}</Text>

                      :

                      <Text style={styles.headerCell1}>{data.MQTuitionFee}</Text>

:
data.preferredBranch1==="Master of Business Administration"?

<Text style={styles.headerCell1}>100000</Text>
:
<Text style={styles.headerCell1}>20000</Text>

                  }


                
                </View> */}


         
              
                  

  


              


  <>
   
                  <>

                  <View style={styles.bodyRow}>
                      <Text style={styles.bodyCell}>Tution Fees</Text>

                      <Text style={styles.headerCell1}></Text>

                    </View>
                    <View style={styles.bodyRow}>
                      <Text style={styles.bodyCell}>Transport Fees</Text>

                      <Text style={styles.headerCell1}></Text>

                    </View>
              
                      <View style={styles.bodyRow}>

                        <Text style={styles.bodyCell}>Breakfast Fees</Text>
                        <Text style={styles.headerCell1}></Text>

                      </View>
                    

                    <View style={styles.bodyRow}>
                      <Text style={styles.bodyCell}>Lunch Fees</Text>
                      <Text style={styles.headerCell1}></Text>

                    </View>
                  </>
            
                  <View style={styles.bodyRow}>
                    <Text style={styles.bodyCell}>Hostel Fees</Text>

                    <Text style={styles.headerCell1}></Text>


                  </View>
            

           
                    <View style={styles.bodyRow}>
                      <Text style={styles.bodyCell}>Scholarship / FG /PMS</Text>

                      <Text style={styles.headerCell1}></Text>

                    </View>


           
                    <View style={styles.bodyRow}>
                      <Text style={styles.bodyCell}>Total Fees</Text>

                      <Text style={styles.headerCell1}></Text>

                    </View>
                 
  </>







                <View style={{ marginTop: 10 }}></View>

                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                  <View style={{ padding: 10 }}>
                    <Text style={{ fontSize: 13 }}>Advance Booking : {data.feeAdvanced} </Text>
                    <Text style={{ fontSize: 12 }}>( non refundable )</Text>
                  </View>
                  <View style={{ padding: 10 }}>
                    <Text style={{ fontSize: 13 }}>Amount Paid : {data.feeAdvancePaid}  </Text>
                  </View>
                </View>




              </View>






            </View>

          </View>


          {
           ( data.remarks !="" && data.remarks !== undefined && data.remarks !== null)
            &&
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 30, width: "100%", position: "absolute", bottom: 50, alignItems: "center" }}>



            <View style={{ alignItems: "center" }}>
              <Text style={{ fontSize: 12, textAlign: "center" }}>Remarks : </Text>
              <Text style={{ fontSize: 12, textAlign: "center" }}>
                {
                  data.remarks
                } </Text>
            </View>
            
            </View>
          }

 




          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 30, width: "100%", position: "absolute", bottom: 0, alignItems: "center" }}>

            <View style={{ alignItems: "center" }}>
              <Text style={{ fontSize: 12 }}>{new Date().toLocaleDateString('en-GB', Options)}</Text>
              <Text style={{ fontSize: 12, textAlign: "center" }}>Date</Text>
            </View>
            <View>
              <Text style={{ fontSize: 12 }}>Parents Signature </Text>
            </View>
            <View>
              <Text style={{ fontSize: 12 }}>Accounts Officer </Text>
            </View>
            <View>
              <Text style={{ fontSize: 12 }}>Admission Coordinator </Text>
            </View>
          </View>
        </View>

      </Page>





    </Document>
  );

  
 useEffect(()=>{
    // dowmload pdf 


 },[])
    
  
    return (
        <PDFDownloadLink document={<MyDocument />} fileName={`${data.mobileNumber}`}>
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
    </PDFDownloadLink>
    );
  }
  