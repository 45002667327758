import React, { useEffect, useState } from 'react'
import NavBar from './components/NavBar'
import ThemeSettings from './components/ThemeSettings'
import SideBar from './components/Sidebar'
import AdmissionRegistrationForm from './pages/AdmissionRegistrationForm'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdmissionStatus from './components/AdmissionStatus'
import ViewAdmission from './components/ViewAdmission'
import SingleStudentAdmissionData from './components/SingleStudentAdmissionData'
import Test from './components/test'
import AdmissionPdf from './components/AdmissionPdf'
import AdmissionPrint from './components/AdmissionPrint'
import Logo from "./components/logo.jpg";
import OverAll from './components/OverAll';
import axios from 'axios'


export default function App() {

  const [newPassword, setNewPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [valid,setValid] = useState(true)


  useEffect(()=>{
    axios.get("https://api.dremerz.net/api/InovationAdmission")
    .then((res)=>{
      console.log(res.data.length)
    })
    .catch((err)=>{
      console.log(err)
    })

  },[])


  return (

  valid?
  <BrowserRouter>
  <div class="container-scroller">
    <NavBar></NavBar>
    <div class="container-fluid page-body-wrapper">
      <SideBar></SideBar>
      <div className="main-panel">
        <div className="content-wrapper">

    <Routes>
      <Route path="/" >
        <Route index element={<AdmissionRegistrationForm />} />
        <Route path="admission-registration-form" element={<AdmissionRegistrationForm />} />
        <Route path="admission-status" element={<AdmissionStatus />} />
        <Route path="view-admission" element={<ViewAdmission />} />
        <Route path="single-admission" element={<SingleStudentAdmissionData />} />
        <Route path="test" element={<Test />} />
        <Route path="fees-details" element={<AdmissionStatus />} />
        <Route path="admission-pdf" element={<AdmissionPdf />} />
        <Route path="admission-print" element={<AdmissionPrint />} />
        <Route path="overview-print" element={<OverAll />} />
        
      </Route>
    </Routes>

        </div>
      </div>
    </div>
  </div>
  </BrowserRouter>
:
<div className="container-scroller">
<div className="container-fluid page-body-wrapper full-page-wrapper">
  <div className="content-wrapper d-flex align-items-center auth px-0">
    <div className="row w-100 mx-0">
      <div className="col-lg-4 mx-auto">
        <div className="auth-form-light text-left py-5 px-4 px-sm-5">
          <div className="brand-logo" style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
            <img
              src={Logo}
              alt="logo"
            />
            
          </div>
          <h1 style={{color:"black",textAlign:"center"}}>ADMISSION</h1>  
        <br></br>
          <form className="pt-3">
            <div className="form-group">
            <label>USER NAME</label>  <input
            style={{minWidth:"100%"}}
            type="text"
            placeholder="Enter username"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}

                className="form-control form-control-lg"
                id="exampleInputEmail1"
              />
            </div>
            <div className="form-group">
            <label>PASSWORD</label>   <input
               style={{minWidth:"100%"}}
                className="form-control form-control-lg"
                id="exampleInputPassword1"
                type="password"
                placeholder="Enter password"
                value={newPassword1}
                onChange={(e) => setNewPassword1(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <span  type="submit" 
                className="btn btn-block btn-primary btn-lg font-weight-medium "
               onClick={()=>{
                 if (newPassword === "pec-admission" && newPassword1=== "Admission@0987") {

              
                   setValid(true)
                 }
                 else{
                   alert("Wrong UserName / Password ")
                 }
               }}
              >
                LOGIN
              </span>


       
            </div>
            
          
          </form>
        </div>
      </div>
    </div>
  </div>
  {/* content-wrapper ends */}
</div>
</div>

  )
}

