import React from 'react';
import Logo from "./logo.jpg"

export default function NavBar() {
  return (
   <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-between">
 
   <div style={{color:"white",}}>
  <img src={Logo} alt="logo" style={{height:"50px",width:"50px",borderRadius:100,}}/>
  &nbsp;&nbsp;
   P.E.C
    </div> 
    <div className="notification">
     
    </div>
  </div>
  <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
    <div className="d-none d-lg-flex">
     
    </div>
    <div className="navbar-social-link d-none d-xl-flex align-items-center " style={{color:"white"}}>
      <img src={Logo} alt="logo" style={{height:"30px",width:"30px",borderRadius:100}}/>
     &nbsp; PRATHYUSHA ENGINEERING COLLEGE
    </div>
    
    <button
      className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
      type="button"
      data-toggle="offcanvas"
    >
      <span className="mdi mdi-menu" />
    </button>
  </div>
</nav>

  )
}
