import React from 'react'
import PersonalDetailsAdmissionRegistrationForm from '../components/PersonalDetailsAdmissionRegistrationForm'
import EducationDetailsAdmissionRegistrationForm from '../components/EducationDetailsAdmissionRegistrationForm'

export default function AdmissionRegistrationForm() {
  return (
    <>
     <div class="text-dark">
              <h2 class="mb-1 font-weight-bold">Admission Registration Form</h2>
             
              <p class="text-small mb-0" style={{color:"grey"}}>Please fill in the form below to register for admission</p>
            </div>
<br />
  
  <PersonalDetailsAdmissionRegistrationForm></PersonalDetailsAdmissionRegistrationForm>


    </>
   
  )
}
