import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Options from './Options';

export default function PersonalDetailsAdmissionRegistrationForm() {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    const [formData, setFormData] = useState({
        table: "admission",
        candidateName: '',
        preferredBranch1: 'B.E Computer Science Engineering',
        preferredBranch2:'',
        preferredBranch3: '',
        address: '',
        boardingPoint: '',
        guardianOccupation: '',
        guardianName: '',
        email: '',
        mobileNumber: '',
        dob: '',
        gender: 'Male',
        community: '',
        aadharNumber: '',
        tenthSchoolName: '',
        tenthTotalMarks: '',
        tenthBoard:"State Board",
        tenthMedium: 'English',
        isFirstGraduate: 'NO',
        isAppliedForTNEA: 'NO',
        twelfthExamRegNo: '',
        twelfthTotalMarks: '',
        twelfthBoard:"State Board",
        twelfthMedium: 'English',
        twelfthSchoolAddress: '',
        mathematicsMarks: '',
        physicsMarks: '',
        chemistryMarks: '',
        cutOffMarks: '',
        fourthSubject: '',
        status: "Pending",
        reference: "Direct",
        referenceName: "",
        admissionFee: "10",
        course: "UNDER GRADUATE",
        breakFast: "YES",
        "GQTuitionFee": "86000",
        "MQTuitionFee": "142000",
        "hostelFee": "105000",
        "breakfastFee": "8500",
        "lunchFee": "23500",
        apRtn:"TN",
        mqRdq:"MQ",
        dateOfVisit:"",
        refDep:"",
        refYear:"",
        natureOfVisit:"",
        hostelerOrDayScholar:"",
        routeNumber:"",
        routeName:"",
        ugCollegeName   : "",
UgDepartment    : "",
ugcgpa: "",
ugYearofPassing: "",
applicationId: "",
counseledBy: "",
feeAdvancePaid: "",
feeAdvanced: "",
remarks: "",
scholarship: "",
transportFee: "",

        date :new Date().toLocaleDateString('en-GB', Options)
    });
    // formData.preferredBranch1= formData.course === "UNDER GRADUATE" ? "B.E Computer Science Engineering" : "M.E Computer Science Engineering";
    // formData.preferredBranch2= formData.course === "UNDER GRADUATE" ? "BTech Information Technology" : "M.TECH BIO TECHNOLOGY";  
    // formData.preferredBranch3= formData.course === "UNDER GRADUATE" ? "B.TECH BIO TECHNOLOGY" : "M.E COMMUNICATION SYSTEMS";

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    function convertToFourDigit(number) {
        // Convert the number to a string
        let numberString = number.toString();
        
        // If the number is already 4 digits or more, return it as is
        if (numberString.length >= 5) {
            return numberString;
        }
        
        // Otherwise, pad the number with leading zeros until it becomes 4 digits long
        while (numberString.length < 5) {
            numberString = '0' + numberString;
        }
        
        return numberString;
    }


    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]:formatDateString(value) 
        }));
      };
    
      // Function to format the date as dd-mm-yyyy
      const formatDateString = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Generate application ID
            const res = await axios.get(`https://api.dremerz.net/api/InovationAdmission/`);
           
            // Set the applicationId in the formData
            setFormData(
                {
                    ...formData,
                });
    
            const modifiedFormData = Object.fromEntries(
                Object.entries(formData).map(([key, value]) => [key, typeof value === 'string' ? value.replace(/,/, '-') : value])
            );




        
            console.log("Form1 Data", modifiedFormData);
            console.log("Form01 Data", formData);
                
            const res2 = await axios.get(`https://api.dremerz.net/api/InovationAdmission/?mobileNumber=${formData.mobileNumber}`);
            if (res2.data.length > 0) {
                alert("Mobile Number already exists");
            } else {
                await axios.post("https://api.dremerz.net/api/InovationAdmission/", modifiedFormData)
                .then((res) => {
                    alert("Admission Registration Form Submitted Successfully");
                   
                }
                )
                .catch((err) => {
                    console.log(err);
                    alert("Error Occurred");
                });

            }
        } catch (err) {
            console.log("error");
            console.log(err);
            alert("Error Occurred");
        }
    };
    
    
    



    return (
        <>
            <div className="col-12 grid-margin" style={{ backgroundColor: "white", color: "black" }}>
                <div className="card-body">
                    <h4 className="card-title">Personal Details</h4>
                    <form className="form-sample" onSubmit={handleSubmit}>
                        <p className="card-description" style={{ color: "grey" }}> Please fill your personal details</p>
                        <br />



                        <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Nature of Visit</label>
                                        <div className="col-sm-9">
                                            <select name="natureOfVisit" value={formData.natureOfVisit} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                                <option value="Direct">Direct</option>
                                                <option value="Online">Online</option>

                       

                                            </select>
                                        </div>
                                    </div>

                                </div>
                              

                        <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label"> Date of Visit</label>
                                <div className="col-sm-9">
                                        <input  type="date" className="form-control" name="dateOfVisit" value={formData.dateOfVisit} onChange={handleChange} />

                                    </div>
                                    </div>
                                    </div>

                                    </div>


                        <div className="row">
                        <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">How did you come to know about PEC?</label>
                                    <div className="col-sm-9">
                                        <select  name="reference" value={formData.reference} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                           
                                            <option value="Direct">Direct</option>
                                            <option value="Friends">Friends</option>
                                            <option value="Students">Students</option>
                                            <option value="Alumni">Alumni</option>
                                            <option value="Parents">Parents</option>
                                            <option value="Relatives">Relatives</option>   
                                            <option value="Staff">Staff</option>
                                            <option value="Others">Others</option>

                                        </select>
                                    </div>
                                </div>

                            </div>
                            {
                                formData.reference ==="Staff"
                                ?
                                <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label"> Staff Name</label>
                                <div className="col-sm-9">
                                        <input  type="text" className="form-control" name="referenceName" value={formData.referenceName} onChange={handleChange} />

                                    </div>
                                    </div>
                                    </div>
                                    :
                                    <div></div>

                            }
       {
                                formData.reference ==="Others"
                                ?
                                <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label"> Name </label>
                                <div className="col-sm-9">
                                        <input  type="text" className="form-control" name="referenceName" value={formData.referenceName} onChange={handleChange} />

                                    </div>
                                    </div>
                                    </div>
                                    :
                                    <div></div>

                            }


{
                                formData.reference ==="Students"
                                ?
                                <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label"> Name </label>
                                <div className="col-sm-9">
                                        <input  type="text" className="form-control" name="referenceName" value={formData.referenceName} onChange={handleChange} />

                                    </div>
                                    </div>
                                    </div>
                                    :
                                    <div></div>

                            }

{
                                formData.reference ==="Alumni"
                                ?
                                <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label"> Name </label>
                                <div className="col-sm-9">
                                        <input  type="text" className="form-control" name="referenceName" value={formData.referenceName} onChange={handleChange} />

                                    </div>
                                    </div>
                                    </div>
                                    :
                                    <div></div>

                            }

{
                                formData.reference ==="Parents"
                                ?
                                <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label"> Name </label>
                                <div className="col-sm-9">
                                        <input  type="text" className="form-control" name="referenceName" value={formData.referenceName} onChange={handleChange} />

                                    </div>
                                    </div>
                                    </div>
                                    :
                                    <div></div>

                            }

                            </div>
                       
<div className="row">
{
                             (   formData.reference ==="Students" || formData.reference === "Staff" || formData.reference === "Alumni"  )
                             &&
                             
                                // dropdown for department
                                <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">{formData.reference} Department</label>
                                    <div className="col-sm-9">
                                        <select name="refDep" value={formData.refDep} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                        

                                            <option value={""}>SELECT</option>
                                            <option value={"CSE"}>CSE</option>
                                            <option value={"ECE"}>ECE</option>
                                            <option value={"EEE"}>EEE</option>
                                            <option value={"MECH"}>MECH</option>
                                            <option value={"CSE (Cyber Security)"}>CSE (Cyber Security)</option>
                                            <option value={"CSE (AI & ML)"}>CSE (AI & ML)</option>
                                            <option value={"IT"}>IT</option>
                                            <option value={"AI & DS"}>AI & DS</option>
                                            <option value={"BT"}>BT</option>
                                            <option value={"CSBS"}>CSBS</option>
                                            <option value={"AE"}>AE</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
}


{



                                (   formData.reference ==="Students" )
                                &&
                                // get student year in dp
                                <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">{formData.reference} Year</label>
                                    <div className="col-sm-9">
                                        <select name="refYear" value={formData.refYear} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                            <option value={""}>SELECT</option>
                                            <option value={"I"}>I</option>
                                            <option value={"II"}>II</option>
                                            <option value={"III"}>III</option>
                                            <option value={"IV"}>IV</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
}
</div>

                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Counseled by</label>
                                    <div className="col-sm-9">
                                        <input  type="text" className="form-control" name="counseledBy" value={formData.counseledBy} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Name of the Candidate</label>
                                    <div className="col-sm-9">
                                        <input required type="text" className="form-control" name="candidateName" value={formData.candidateName} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Preferred Course</label>
                                    <div className="col-sm-9">
                                        <select name="course" value={formData.course} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                            <option value={"UNDER GRADUATE"}>UNDER GRADUATE</option>
                                            <option value={"POST GRADUATE"}>POST GRADUATE</option>
                                            </select>
                                    </div>
                                </div>
                            </div>

{
                                formData.course ==="UNDER GRADUATE" ?
             <>
             <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Preferred Branch1</label>
                                    <div className="col-sm-9">
                                        <select name="preferredBranch1" value={(formData.preferredBranch1)} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                            <option value="B.E Computer Science Engineering">B.E Computer Science and Engineering</option>
                                            <option value="B.E Electronics and Communication Engineering">B.E Electronics and Communication Engineering</option>
                                            <option value="B.E Electrical and Electronics Engineering">B.E Electrical and Electronics Engineering</option>
                                            <option value="B.E Mechanical Engineering">B.E Mechanical Engineering</option>
                                            
                                            <option value="B.E Computer Science Engineering ( Cyber Security )">B.E Computer Science and Engineering ( Cyber Security )</option>
                                            <option value="B.E Computer Science Engineering ( Artificial Intelligence & Machine Learning )">B.E Computer Science and Engineering ( Artificial Intelligence & Machine Learning )</option>
                                            <option value="B.TECH Information Technology">B.TECH Information Technology</option>
                                            <option value="B.TECH ARTIFICIAL INTELLIGENCE AND DATA SCIENCE">B.TECH ARTIFICIAL INTELLIGENCE AND DATA SCIENCE</option>
                                            <option value="B.TECH BIO TECHNOLOGY">B.TECH BIO TECHNOLOGY</option>
                                            <option value="B.TECH COUMPUTER SCIENCE AND BUSINESS SYSTEMS">B.TECH COUMPUTER SCIENCE AND BUSINESS SYSTEMS</option>
                                            <option value="B.TECH AGRICULTURAL ENGINEERING">B.TECH AGRICULTURAL ENGINEERING</option>
                                            
                                      
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Preferred Branch2</label>
                                    <div className="col-sm-9">
                                        <select name="preferredBranch2" value={formData.preferredBranch2} onChange={handleChange} className="form-control" style={{ color: "black" }}>
        <option value="">SELECT</option>   
                                            <option value="B.TECH Information Technology">B.TECH Information Technology</option>
                                              <option value="B.E Computer Science Engineering">B.E Computer Science and Engineering</option>
                                            <option value="B.E Electronics and Communication Engineering">B.E Electronics and Communication Engineering</option>
                                            <option value="B.E Electrical and Electronics Engineering">B.E Electrical and Electronics Engineering</option>
                                            <option value="B.E Mechanical Engineering">B.E Mechanical Engineering</option>
                                            
                                            <option value="B.E Computer Science Engineering ( Cyber Security )">B.E Computer Science and Engineering ( Cyber Security )</option>
                                            <option value="B.E Computer Science Engineering ( Artificial Intelligence & Machine Learning )">B.E Computer Science and Engineering ( Artificial Intelligence & Machine Learning )</option>
                                            <option value="B.TECH ARTIFICIAL INTELLIGENCE AND DATA SCIENCE">B.TECH ARTIFICIAL INTELLIGENCE AND DATA SCIENCE</option>
                                            <option value="B.TECH BIO TECHNOLOGY">B.TECH BIO TECHNOLOGY</option>
                                            <option value="B.TECH COUMPUTER SCIENCE AND BUSINESS SYSTEMS">B.TECH COUMPUTER SCIENCE AND BUSINESS SYSTEMS</option>
                                            <option value="B.TECH AGRICULTURAL ENGINEERING">B.TECH AGRICULTURAL ENGINEERING</option>
                                         </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Preferred Branch3</label>
                                    <div className="col-sm-9">
                                        <select name="preferredBranch3" value={formData.preferredBranch3} onChange={handleChange} className="form-control" style={{ color: "black" }}>
        <option value="">SELECT</option>   
                                        <option value="B.TECH BIO TECHNOLOGY">B.TECH BIO TECHNOLOGY</option>
                                              <option value=">B.E Computer Science Engineering">B.E Computer Science and Engineering</option>
                                            <option value="B.E Electronics and Communication Engineering">B.E Electronics and Communication Engineering</option>
                                            <option value="B.E Electrical and Electronics Engineering">B.E Electrical and Electronics Engineering</option>
                                            <option value="B.E Mechanical Engineering">B.E Mechanical Engineering</option>
                                            
                                            <option value="B.E Computer Science Engineering ( Cyber Security )">B.E Computer Science and Engineering ( Cyber Security )</option>
                                            <option value="B.E Computer Science Engineering ( Artificial Intelligence & Machine Learning )">B.E Computer Science and Engineering ( Artificial Intelligence & Machine Learning )</option>
                                            <option value=">B.TECH Information Technology">B.TECH Information Technology</option>
                                            <option value="B.TECH ARTIFICIAL INTELLIGENCE AND DATA SCIENCE">B.TECH ARTIFICIAL INTELLIGENCE AND DATA SCIENCE</option>
                                            <option value="B.TECH COUMPUTER SCIENCE AND BUSINESS SYSTEMS">B.TECH COUMPUTER SCIENCE AND BUSINESS SYSTEMS</option>
                                            <option value="B.TECH AGRICULTURAL ENGINEERING">B.TECH AGRICULTURAL ENGINEERING</option>
                                         </select>
                                    </div>
                                </div>
                            </div>
             </>   
             
             :

             <>
         

             <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Preferred Branch1</label>
                                    <div className="col-sm-9">
                                        <select name="preferredBranch1" value={formData.preferredBranch1} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                            <option value="">SELECT</option>
                                            <option value="MBA">MBA</option>
                                            <option value="M.E Computer Science Engineering">M.E Computer Science Engineering</option>
                                            <option value="M.TECH BIO TECHNOLOGY">M.TECH BIO TECHNOLOGY</option>   
                                            <option value="M.E COMMUNICATION SYSTEMS">M.E COMMUNICATION SYSTEMS</option>
                                            <option value="M.E POWER ELECTRONICS AND DRIVES">M.E POWER ELECTRONICS AND DRIVES</option>
                                            <option value="M.E CAD/CAM">M.E CAD/CAM</option>
                                            <option value="Master of Business Administration">{("Master of Business Administration").toUpperCase()}</option>
                                            <option value="M.E VLSI Design">{
                                                ("VLSI Design").toUpperCase()
                                            }</option>



                                        </select>
                                    </div>
                                </div>
                            </div>
{/* {
    formData.course ==="UNDER GRADUATE" &&
<>
<div className="col-md-6">
<div className="form-group row">
    <label className="col-sm-3 col-form-label">Preferred Branch2</label>
    <div className="col-sm-9">
        <select name="preferredBranch2" value={formData.preferredBranch2} onChange={handleChange} className="form-control" style={{ color: "black" }}>
        <option value="">SELECT</option>   
        <option value="M.TECH BIO TECHNOLOGY">M.TECH BIO TECHNOLOGY</option>   
            <option value="M.E Computer Science Engineering">M.E Computer Science and Engineering</option>
            <option value="M.E COMMUNICATION SYSTEMS">M.E COMMUNICATION SYSTEMS</option>
            <option value="M.E POWER ELECTRONICS AND DRIVES">M.E POWER ELECTRONICS AND DRIVES</option>
            <option value="M.E CAD/CAM">M.E CAD/CAM</option>
            <option value="Master of Business Administration">Master of Business Administration</option>




        </select>
    </div>
</div>
</div>

<div className="col-md-6">
<div className="form-group row">
    <label className="col-sm-3 col-form-label">Preferred Branch3</label>
    <div className="col-sm-9">
        <select name="preferredBranch3" value={formData.preferredBranch3} onChange={handleChange} className="form-control" style={{ color: "black" }}>
        <option value="">SELECT</option>   
        <option value="M.E COMMUNICATION SYSTEMS">M.E COMMUNICATION SYSTEMS</option>
            <option value="M.E Computer Science Engineering">M.E Computer Science and Engineering</option>
            <option value="M.TECH BIO TECHNOLOGY">M.TECH BIO TECHNOLOGY</option>   
            <option value="M.E POWER ELECTRONICS AND DRIVES">M.E POWER ELECTRONICS AND DRIVES</option>
            <option value="M.E CAD/CAM">M.E CAD/CAM</option>
            <option value="Master of Business Administration">Master of Business Administration</option>




        </select>
    </div>
</div>
</div>
</>
} */}

</>
             
}
                            

                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Father / Guardian Name</label>
                                    <div className="col-sm-9">
                                        <input required type="text" className="form-control" name="guardianName" value={formData.guardianName} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Address Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Address</label>
                                    <div className="col-sm-9">
                                        <input required type="text" className="form-control" name="address" value={formData.address} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Are you willing to join as Hosteller/ Day scholar</label>
                                        <div className="col-sm-9">
                                            <select name="hostelerOrDayScholar" value={formData.hostelerOrDayScholar} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                                <option value="">Select</option>
                                                <option value="Hosteller">Hosteller</option>
                                                <option value="Day-Scholar">Day-Scholar</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                {
                                    formData.hostelerOrDayScholar ==="Day-Scholar"
                                    &&
                                    <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Route Number</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="routeNumber" value={formData.routeNumber} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                }

                                

{
                                    formData.hostelerOrDayScholar ==="Day-Scholar"
                                    &&
                                    <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Route Name</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="routeName" value={formData.routeName} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                }
                            {/* Boarding Point / Hostel Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Boarding Point / Hostel</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="boardingPoint" value={formData.boardingPoint} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Guardian Occupation Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Father / Guardian Occupation</label>
                                    <div className="col-sm-9">
                                        <input required type="text" className="form-control" name="guardianOccupation" value={formData.guardianOccupation} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Email ID Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Email ID</label>
                                    <div className="col-sm-9">
                                        <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Mobile Number Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Mobile Number</label>
                                    <div className="col-sm-9">
                                        <input required type="text" className="form-control" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Date of Birth Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Date of Birth</label>
                                    <div className="col-sm-9">
                                        <input required type="date" className="form-control" name="dob" value={formData.dob} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Gender Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Gender</label>
                                    <div className="col-sm-9">
                                        <select name="gender" value={formData.gender} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                            <option>Male</option>
                                            <option>Female</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Community Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Community</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="community" value={formData.community} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            {/* Aadhar Number Field */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Aadhar Number</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="aadharNumber" value={formData.aadharNumber} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">BreakFast</label>
        <div className="col-sm-4">
            <div className="form-check">
                <label className="">
                    <input
                        type="radio"
                        className="form-check-input"
                        id="membershipRadios1"
                        defaultValue=""
                        defaultChecked=""
                        name="breakFast"
                        value="YES"
                        onChange={handleChange}


                    />
                    YES
                </label>
            </div>
        </div>
        <div className="col-sm-5">
            <div className="form-check">
                <label className="">
                    <input
                        type="radio"
                        className="form-check-input"

                        id="membershipRadios2"
                        defaultValue="option2"
                        name="breakFast"
                        value="NO"
                        onChange={handleChange}

                    />
                    NO
                </label>
            </div>
        </div>
    </div>

</div>
             <div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">Applied for TNEA </label>
        <div className="col-sm-4">
            <div className="form-check">
                <label className="">
                    <input
                        type="radio"
                        className="form-check-input"
                        id="membershipRadios1"
                        defaultValue=""
                        defaultChecked=""
                        name="isAppliedForTNEA"
                        value="YES"
                        onChange={handleChange}


                    />
                    YES
                </label>
            </div>
        </div>
        <div className="col-sm-5">
            <div className="form-check">
                <label className="">
                    <input
                        type="radio"
                        className="form-check-input"

                        id="membershipRadios2"
                        defaultValue="option2"
                        name="isAppliedForTNEA"
                        value="NO"
                        onChange={handleChange}

                    />
                    NO
                </label>
            </div>
        </div>
    </div>

</div>


                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">State</label>
                                    <div className="col-sm-9">
                                        <select name="apRtn" value={formData.apRtn} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                            <option value={"TN"}>TN</option>
                                            <option value={"AP"}>AP</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            {
                                formData.apRtn ==="TN"
                                &&

                                <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Quota</label>
                                    <div className="col-sm-9">
                                        <select name="mqRdq" value={formData.mqRdq} onChange={handleChange} className="form-control" style={{ color: "black" }}>
                                        {/* <option value={""}>SELECT</option> */}
                                            <option value={"MQ"}>MQ</option>
                                            <option value={"GQ"}>GQ</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            }



                            {/* ...other personal detail fields... */}

                       
                        </div>

<br />
<h4 className="card-title">Education Details</h4>

                        <p className="card-description" style={{ color: "grey" }}> Please fill your education details</p>
                        <br />
                        <p class="card-description">
                            10th Standard :
                        </p>
                        <br />
                        
                        <div className="row">

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">School Name</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="tenthSchoolName" value={formData.tenthSchoolName} onChange={handleChange} />
        </div>
    </div>
</div>

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">Total Marks ( Specify like - 499/500 )</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="tenthTotalMarks" value={formData.tenthTotalMarks} onChange={handleChange} />
        </div>
    </div>
</div>

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label"> Board</label>
        <div className="col-sm-9">
            <select style={{ color: "black" }} class="form-control" name="tenthBoard" value={formData.tenthBoard} onChange={handleChange}>
                <option>State Board</option>
                <option>CBSE</option>
                <option>others</option>
            </select>
        </div>
    </div>
</div>

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label"> Medium</label>
        <div className="col-sm-9">
            <select style={{ color: "black" }} class="form-control" name="tenthMedium" value={formData.tenthMedium} onChange={handleChange}>
            <option value={"Tamil"}>Tamil</option>
                <option value={"Telugu"}>Telugu </option>
                <option value={"English"}>English</option>
                <option value={"Others"}>Others</option>
            </select>
        </div>
    </div>
</div>

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">First Graduate </label>
        <div className="col-sm-4">
            <div className="form-check">
                <label className="">
                    <input
                        type="radio"
                        className="form-check-input"
                        id="membershipRadios1"
                        defaultValue=""
                        defaultChecked=""
                        name="isFirstGraduate"
                        value="YES"
                        onChange={handleChange}


                    />
                    YES
                </label>
            </div>
        </div>
        <div className="col-sm-5">
            <div className="form-check">
                <label className="">
                    <input
                        type="radio"
                        className="form-check-input"

                        id="membershipRadios2"
                        defaultValue="option2"
                        name="isFirstGraduate"
                        value="NO"
                        onChange={handleChange}

                    />
                    NO
                </label>
            </div>
        </div>
    </div>

</div>



</div>

<br />
<p class="card-description">
12th Standard :
</p>
<br />


<div className="row">

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">Exam Reg No</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="twelfthExamRegNo" value={formData.twelfthExamRegNo} onChange={handleChange} />
        </div>
    </div>
</div>

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">Total Marks ( Specify like - 599/600 )</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="twelfthTotalMarks" value={formData.twelfthTotalMarks} onChange={handleChange} />
        </div>
    </div>
</div>

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label"> Board</label>
        <div className="col-sm-9">
            <select style={{ color: "black" }} class="form-control" name="twelfthBoard" value={formData.twelfthBoard} onChange={handleChange}>
                <option>State Board</option>
                <option>CBSE</option>
                <option>others</option>
            </select>
        </div>
    </div>
</div>


<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">Medium</label>
        <div className="col-sm-9">
            <select style={{ color: "black" }} class="form-control" name="twelfthMedium" value={formData.twelfthMedium} onChange={handleChange}>
                <option value={"Tamil"}>Tamil</option>
                <option value={"Telugu"}>Telugu </option>
                <option value={"English"}>English</option>
                <option value={"Others"}>Others</option>

            </select>
        </div>
    </div>
</div>

{
    formData.course ==="UNDER GRADUATE" &&
    <>
<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">School Address ( in short )</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="twelfthSchoolAddress" value={formData.twelfthSchoolAddress} onChange={handleChange} />
        </div>
    </div>
</div>


<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">Mathematics Marks</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="mathematicsMarks" value={formData.mathematicsMarks} onChange={handleChange} />
        </div>
    </div>
</div>

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">Physics Marks</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="physicsMarks" value={formData.physicsMarks} onChange={handleChange} />
        </div>
    </div>
</div>


<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label"> Chemistry Marks</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="chemistryMarks" value={formData.chemistryMarks} onChange={handleChange} />
        </div>
    </div>
</div>


<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label"> Cut off Marks</label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="cutOffMarks" value={formData.cutOffMarks} onChange={handleChange} />
        </div>
    </div>
</div>
<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label"> Fourth Subject </label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="fourthSubject" value={formData.fourthSubject} onChange={handleChange} />
        </div>
    </div>
</div>
</>
}



</div>

{
    formData.course ==="POST GRADUATE" &&
    <>
         
                        <br />
                        <p class="card-description">
                            UG Degree :
                        </p>
                        <br />

                        <div className='row'>
   
   <div className="col-md-6">
       <div className="form-group row">
           <label className="col-sm-3 col-form-label">College Name</label>
           <div className="col-sm-9">
               <input type="text" className="form-control" name="ugCollegeName" value={formData.ugCollegeName} onChange={handleChange} />
           </div>
       </div>
       <div className="form-group row">
           <label className="col-sm-3 col-form-label">Department</label>
           <div className="col-sm-9">
               <input type="text" className="form-control" name="UgDepartment" value={formData.UgDepartment} onChange={handleChange} />
           </div>
       </div>
   
   
       <div className="form-group row">
           <label className="col-sm-3 col-form-label">CGPA / Percentage</label>
           <div className="col-sm-9">
               <input type="text" className="form-control" name="ugcgpa" value={formData.ugcgpa} onChange={handleChange} />
           </div>
       </div>
   
   
       <div className="form-group row">
           <label className="col-sm-3 col-form-label">Year of Passing</label>
           <div className="col-sm-9">
               <input type="text" className="form-control" name="ugYearofPassing" value={formData.ugYearofPassing} onChange={handleChange} />
           </div>
       </div>
   
   </div>
       
   
   
   
   
       </div>
    </>
  
}

<div className="col-md-6">
    <div className="form-group row">
        <label className="col-sm-3 col-form-label"> Remarks </label>
        <div className="col-sm-9">
            <input type="text" className="form-control" name="remarks" value={formData.remarks} onChange={handleChange} />
        </div>
    </div>
</div>


<div className="col-md-12" style={{display:"flex",justifyContent:"right"}}>
                                <button type="submit" className="btn btn-primary mr-2">Submit</button>
                            </div>

                    </form>


               
                </div>


               
          
            </div>

           
          
        </>
    );
}
