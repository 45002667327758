

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


export default function Overall() {

  const location = useLocation();
  const [data, setData] = useState(location.state.data);

console.log(data);
  const convertJsonToCsv = (jsonData) => {
    if (!jsonData || jsonData.length === 0) return '';

    const header = Object.keys(jsonData[0]).join(',');
    const rows = jsonData.map((row) => Object.values(row).join(','));
    return header + '\n' + rows.join('\n');
  };

  const handleExportToExcel = () => {
    // Convert JSON data to CSV format
    const csvData = convertJsonToCsv(data);

    // Create a Blob object containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };
  

  

  return (
      <div>
      <button onClick={handleExportToExcel}> Export to Excel</button>
    </div>
  );
}
