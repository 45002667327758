import React from 'react'

export default function Options() {
     let options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      };
  return ({options})
}
