import axios from 'axios'
import React, { useEffect, useState, } from 'react'
import { useLocation } from 'react-router-dom'

export default function AdmissionStatus() {
  const location = useLocation();
  const [data, setData] = useState(location.state.data);
  console.log("data", data);

  const [totalFee, setTotalFee] = useState(0);

  useEffect(() => {
    setTotalFee(
      (parseInt(data.GQTuitionFee) || 0) +
      (parseInt(data.transportFee) || 0) +
      (parseInt(data.breakfastFee) || 0) +
      (parseInt(data.lunchFee) || 0) +
      (parseInt(data.hostelFee) || 0) -
      (parseInt(data.scholarship) || 0)
    );
  }, [data]);

  return (
    <>
      <div className="text-dark">
        <h2 className="mb-1 font-weight-bold">Fees Details</h2>
        <p className="text-small mb-0" style={{ color: "grey" }}>Here you can view your fees details</p>
      </div>
      <br />

      <div className="col-lg-12 grid-margin stretch-card" style={{ color: "black" }}>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered" style={{ color: "black" }}>
                <thead>
                </thead>

                <tbody>

                <tr>
                    <th>Particulars</th>
                    {
                      data.course === "UNDER GRADUATE" &&
                      <th>GQ FEES</th>
                    }
                 {
                  data.preferredBranch1 ==="Master of Business Administration" &&
                  <th>GQ FEES</th>
                 }
                    
                    <th>MQ FEES</th>
                  </tr>
                  {
                    data.course === "UNDER GRADUATE" ?

                    <>
                         
               
             


                  <tr>
                    <td>Tuition Fees</td>
                    <td>
                      <input
                        value={data.GQTuitionFee}
                        className="form-control"
                        type="text"
                        onChange={(e) => {
                          setData({ ...data, GQTuitionFee: e.target.value });
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={data.MQTuitionFee}
                        className="form-control"
                        type="text"
                        onChange={(e) => {
                          setData({ ...data, MQTuitionFee: e.target.value });
                        }}
                      />
                    </td>
                  </tr>

                  {!data.boardingPoint?.toLowerCase().includes("hostel")
? (
                    <>
                      <tr>
                        <td>Transport Fees</td>
                        <td>
                          <input
                            value={data.transportFee}
                            onChange={(e) => {
                              setData({ ...data, transportFee: e.target.value });
                            }}
                            className="form-control"
                            type="text"
                          />
                        </td>
                        <td>
                          <input
                            value={data.transportFee}
                            onChange={(e) => {
                              setData({ ...data, transportFee: e.target.value });
                            }}
                            className="form-control"
                            type="text"
                          />
                        </td>
                      </tr>

                      {

                        data.breakFast.toLowerCase()!="no"
&& 
<tr>
                        <td>Mess Breakfast Fees</td>
                        <td>
                          <input
                            value={data.breakfastFee}
                            onChange={(e) => {
                              setData({ ...data, breakfastFee: e.target.value });
                            }}
                            className="form-control"
                            type="text"
                          />
                        </td>
                        <td>
                          <input
                            value={data.breakfastFee}
                            onChange={(e) => {
                              setData({ ...data, breakfastFee: e.target.value });
                            }}
                            className="form-control"
                            type="text"
                          />
                        </td>
                      </tr>
                      }
                      
                      <tr>
                        <td>Mess Lunch Fees</td>
                        <td>
                          <input
                            value={data.lunchFee}
                            onChange={(e) => {
                              setData({ ...data, lunchFee: e.target.value });
                            }}
                            className="form-control"
                            type="text"
                          />
                        </td>
                        <td>
                          <input
                            value={data.lunchFee}
                            onChange={(e) => {
                              setData({ ...data, lunchFee: e.target.value });
                            }}
                            className="form-control"
                            type="text"
                          />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td>Hostel Fees</td>
                      <td>
                        <input
                          value={data.hostelFee}
                          onChange={(e) => {
                            setData({ ...data, hostelFee: e.target.value });
                          }}
                          className="form-control"
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          value={data.hostelFee}
                          onChange={(e) => {
                            setData({ ...data, hostelFee: e.target.value });
                          }}
                          className="form-control"
                          type="text"
                        />
                      </td>
                    </tr>
                  )}





                  <tr>
                    <td>Scholarship / FG /PMS</td>
                    <td>
                      <input
                        value={data.scholarship}
                        onChange={(e) => {
                          setData({ ...data, scholarship: e.target.value });
                        }}
                        className="form-control"
                        type="text"
                      />
                    </td>

                    <td>
                      <input
                        value={data.scholarship}
                        onChange={(e) => {
                          setData({ ...data, scholarship: e.target.value });
                        }}
                        className="form-control"
                        type="text"
                      />
                    </td>
                  </tr>

                    </>
                    :

data.preferredBranch1 ==="Master of Business Administration"?
                    <>
                     <>
                         
               
             


                         <tr>
                           <td>Tuition Fees</td>
                           <td>
                             <input
                               value={"100000"}
                               className="form-control"
                               type="text"
                               onChange={(e) => {
                                 setData({ ...data, GQTuitionFee: e.target.value });
                               }}
                             />
                           </td>
                           <td>
                             <input

                             value={"100000"}
                               className="form-control"
                               type="text"
                               onChange={(e) => {
                                 setData({ ...data, MQTuitionFee: e.target.value });
                               }}
                             />
                           </td>
                         </tr>
       
                         {!data.boardingPoint?.toLowerCase().includes("hostel")
       ? (
                           <>
                             <tr>
                               <td>Transport Fees</td>
                               <td>
                                 <input
                                   value={data.transportFee}
                                   onChange={(e) => {
                                     setData({ ...data, transportFee: e.target.value });
                                   }}
                                   className="form-control"
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   value={data.transportFee}
                                   onChange={(e) => {
                                     setData({ ...data, transportFee: e.target.value });
                                   }}
                                   className="form-control"
                                   type="text"
                                 />
                               </td>
                             </tr>
       
                             {
       
                               data.breakFast.toLowerCase()!="no"
       && 
       <tr>
                               <td>Mess Breakfast Fees</td>
                               <td>
                                 <input
                                   value={data.breakfastFee}
                                   onChange={(e) => {
                                     setData({ ...data, breakfastFee: e.target.value });
                                   }}
                                   className="form-control"
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   value={data.breakfastFee}
                                   onChange={(e) => {
                                     setData({ ...data, breakfastFee: e.target.value });
                                   }}
                                   className="form-control"
                                   type="text"
                                 />
                               </td>
                             </tr>
                             }
                             
                             <tr>
                               <td>Mess Lunch Fees</td>
                               <td>
                                 <input
                                   value={data.lunchFee}
                                   onChange={(e) => {
                                     setData({ ...data, lunchFee: e.target.value });
                                   }}
                                   className="form-control"
                                   type="text"
                                 />
                               </td>
                               <td>
                                 <input
                                   value={data.lunchFee}
                                   onChange={(e) => {
                                     setData({ ...data, lunchFee: e.target.value });
                                   }}
                                   className="form-control"
                                   type="text"
                                 />
                               </td>
                             </tr>
                           </>
                         ) : (
                           <tr>
                             <td>Hostel Fees</td>
                             <td>
                               <input
                                 value={data.hostelFee}
                                 onChange={(e) => {
                                   setData({ ...data, hostelFee: e.target.value });
                                 }}
                                 className="form-control"
                                 type="text"
                               />
                             </td>
                             <td>
                               <input
                                 value={data.hostelFee}
                                 onChange={(e) => {
                                   setData({ ...data, hostelFee: e.target.value });
                                 }}
                                 className="form-control"
                                 type="text"
                               />
                             </td>
                           </tr>
                         )}
       
       
       
       
       
                         <tr>
                           <td>Scholarship / FG /PMS</td>
                           <td>
                             <input
                               value={data.scholarship}
                               onChange={(e) => {
                                 setData({ ...data, scholarship: e.target.value });
                               }}
                               className="form-control"
                               type="text"
                             />
                           </td>
       
                           <td>
                             <input
                               value={data.scholarship}
                               onChange={(e) => {
                                 setData({ ...data, scholarship: e.target.value });
                               }}
                               className="form-control"
                               type="text"
                             />
                           </td>
                         </tr>
       
                           </>
                 
                    </>
                    :

                    <tr >
                    <td>Tuition Fees</td>
                    <td style={{textAlign:"center"}}>
                    20000
                    </td>
                    </tr>
                  }
          



<tr>
                    <td>Total Fees</td>
                    {
                      data.course === "UNDER GRADUATE"?
                      <td style={{textAlign:"center"}}>
                      {
                     
                        data.apRtn === "TN" ?
                          data.mqRdq === 'GQ' ?
                            (data.boardingPoint?.toLowerCase().includes("hostel"))
  
                              ? parseFloat(data.GQTuitionFee) + parseFloat(data.hostelFee) - parseFloat(data.scholarship ||0)
                              : parseFloat(data.GQTuitionFee) + parseFloat(data.transportFee) + parseFloat(data.breakfastFee) + parseFloat(data.lunchFee)- parseFloat(data.scholarship || 0)
                            :
                            (data.boardingPoint?.toLowerCase().includes("hostel"))
  
                              ? parseFloat(data.MQTuitionFee) + parseFloat(data.hostelFee)- parseFloat(data.scholarship ||0)
                              : parseFloat(data.MQTuitionFee) + parseFloat(data.transportFee) + parseFloat(data.breakfastFee) + parseFloat(data.lunchFee)- parseFloat(data.scholarship ||0)
                          :
                          (data.boardingPoint?.toLowerCase().includes("hostel"))
  
                            ? parseFloat(data.MQTuitionFee) + parseFloat(data.hostelFee)- parseFloat(data.scholarship ||0)
                            : parseFloat(data.MQTuitionFee) + parseFloat(data.transportFee) + parseFloat(data.breakfastFee) + parseFloat(data.lunchFee)- parseFloat(data.scholarship ||0)
  
  
  
  
  
  
  
                      }
  
                      </td>
                      :
                     data.preferredBranch1 ==="Master of Business Administration"?
                       
                        <td style={{textAlign:"center"}}>
                        {
                      
                          data.apRtn === "TN" ?
                            data.mqRdq === 'GQ' ?
                              (data.boardingPoint?.toLowerCase().includes("hostel"))
    
                                ? parseFloat("100000") + parseFloat(data.hostelFee) - parseFloat(data.scholarship ||0)
                                : parseFloat("100000") + parseFloat(data.transportFee||0) + parseFloat(data.breakfastFee) + parseFloat(data.lunchFee)- parseFloat(data.scholarship || 0)
                              :
                              (data.boardingPoint?.toLowerCase().includes("hostel"))
    
                                ? parseFloat("100000") + parseFloat(data.hostelFee)- parseFloat(data.scholarship ||0)
                                : parseFloat(data.MQTuitionFee) + parseFloat(data.transportFee||0) + parseFloat(data.breakfastFee) + parseFloat(data.lunchFee)- parseFloat(data.scholarship ||0)
                            :
                            (data.boardingPoint?.toLowerCase().includes("hostel"))
    
                              ? parseFloat("100000") + parseFloat(data.hostelFee)- parseFloat(data.scholarship ||0)
                              : parseFloat("100000") + parseFloat(data.transportFee ||0) + parseFloat(data.breakfastFee) + parseFloat(data.lunchFee)- parseFloat(data.scholarship ||0)
    
    
    
    
    
    
    
                        }
    
                        </td>
                        :
                        <td style={{textAlign:"center"}}>
                        20000
                        </td>
                    }
                  
                    

</tr>
                  <tr>
                    <td>Booking Details</td>
                    <td>
                      <input
                        value={data.feeAdvanced}
                        onChange={(e) => {
                          setData({ ...data, feeAdvanced: e.target.value });
                        }}
                        className="form-control"
                        type="text"
                      />
                      <br />
                      Advance Booking
                    </td>
                    <td>
                      <input
                        value={data.feeAdvancePaid}
                        onChange={(e) => {
                          setData({ ...data, feeAdvancePaid: e.target.value });
                        }}
                        className="form-control"
                        type="text"
                      />
                      <br />
                      Amount Paid
                    </td>
                  </tr>

                 
                </tbody>
              </table>

              <br />

              <div className="col-md-12" style={{ display: "flex", justifyContent: "right" }}>
                <button
                  onClick={() => {
                    axios
                      .put(`https://api.dremerz.net/api/InovationAdmission/${data.id}/`, data)
                      .then((res) => {
                        console.log(res);
                        alert("Updated Successfully");
                      })
                      .catch((err) => {
                        console.log(err);
                        alert("Error Occurred");
                      });
                  }}
                  type="submit"
                  className="btn btn-primary mr-2"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
