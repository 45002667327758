import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function ViewAdmission() {
    const [data,setData] = useState([]);
    const [data2,setData2] = useState([]);
    const data1 = [];
    const [loader,setLoader] = useState(true);
    const [filter,setFilter] = useState('');
    const [filter1,setFilter1] = useState('');
    const [filter2,setFilter2] = useState('');
    const [filter3,setFilter3] = useState('');
    const [filter4,setFilter4] = useState('');
    const [filter5,setFilter5] = useState('');

    const [detailedTable,setDetailedTable] = useState(false);



    const fetchData = async()=>{
        data1.length = 0;
        const formatDate = (date) => {
            const d = new Date(date);
            let month = "" + (d.getMonth() + 1);
            let day = "" + d.getDate();
            const year = d.getFullYear();
      
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
      
            return [day, month, year].join("/");
          };
          const params = new URLSearchParams();

if (filter1) params.append('mobileNumber', filter1);
if (filter) params.append('preferredBranch1', filter);
if (filter2) params.append('status', filter2);
if (filter3) params.append('applicationId', filter3);
if (filter4) params.append('apRtn', filter4);
if (filter5) params.append('mqRdq', filter5);

const url = `https://api.dremerz.net/api/InovationAdmission/?${params.toString()}`;

    axios.get(url)
    .then(res=>{
        console.log("responce",res.data);
        console.log(filter1,filter,filter2);

        var a =[];
        if (filter === "B.E Computer Science Engineering") {
            a = res.data.filter((item)=>{
                return (item.preferredBranch1).toLowerCase() === ("B.E Computer Science Engineering").toLowerCase()
            }
            )
        }

        else 
        {
            a = res.data;
        }
        setData(a);
        data1.length = 0;

        res.data.map((item,index)=>{
            

            data1.push({
                "SNO": index+1,
                "Date of Visit" : formatDate(item.dateOfVisit) ,
                "Admission Status" : item.status,
                "Application Number" : item.applicationId,
                "Student Name" : item.candidateName,
                "Address" : item.address,
                "State" : item.apRtn,
                "Mobile Number" : item.mobileNumber,
                "Adhaar number" : item.aadharNumber,
                "Refered By" : item.referenceName,
                "Preferenced Branch1" : item.preferredBranch1,
                "Preferenced Branch2" : item.preferredBranch2,
                "Preferenced Branch3" : item.preferredBranch3,
                "Counseled By" : item.counseledBy,
            })
           

        })
        setData2(data1);

        console.log("data1",data1);
        setLoader(false);
    })
    .catch(err=>{
        console.log(err);
        alert("Error Occured");
    })



    }

    useEffect(()=>{
        fetchData()
    },[])
    useEffect(()=>{
        fetchData()
    },[filter,filter1,filter2,filter3,filter4,filter5])
  return (
    <>
         <div class="text-dark">
              <h2 class="mb-1 font-weight-bold">View Admission</h2>
             
              <p class="text-small mb-0" style={{color:"grey"}}>Here you can view your admission details</p>
            </div>
<br />



<div className="col-lg-12 grid-margin stretch-card" style={{color:"black"}}>
  <div className="card">
    <div className="card-body">
        <div  style={{display:"flex",justifyContent:"space-between",}}>
<div>
<h4 className="card-title">Admission Details</h4>
      <p className="card-description">
        Here you can view your admission details
      </p>

</div>

<Link onClick={()=>{
    fetchData();
}} to={"/overview-print"} state={{data:data2}} >

<button className="btn btn-primary" style={{marginTop:20}} 

>Print Summary</button>
</Link>



{/*  */}
{/* <div>
<h4 className="card-title">Toggle to Detailed View</h4>
        <p className="card-description">
            <input type="checkbox" onChange={(e)=>setDetailedTable(e.target.checked)} />
        </p>
</div> */}
        </div>

        <div style={{display:"flex",justifyContent:"space-between",gap:20,alignItems:"center"}}>

            <div style={{textAlign:"center",alignItems:"center",justifyContent:"center"}}> Department</div>

        <select name="preferredBranch2"  onChange={(e)=>{
            setFilter(e.target.value)
        }} className="form-control" style={{ color: "black" }}>
        <option value="">SELECT</option>   
                                            <option value="B.TECH Information Technology">B.TECH Information Technology</option>
                                              <option value="B.E Computer Science Engineering">B.E Computer Science and Engineering</option>
                                            <option value="B.E Electronics and Communication Engineering">B.E Electronics and Communication Engineering</option>
                                            <option value="B.E Electrical and Electronics Engineering">B.E Electrical and Electronics Engineering</option>
                                            <option value="B.E Mechanical Engineering">B.E Mechanical Engineering</option>
                                            
                                            <option value="B.E Computer Science Engineering ( Cyber Security )">B.E Computer Science and Engineering ( Cyber Security )</option>
                                            <option value="B.E Computer Science Engineering ( Artificial Intelligence & Machine Learning )">B.E Computer Science and Engineering ( Artificial Intelligence & Machine Learning )</option>
                                            <option value="B.TECH ARTIFICIAL INTELLIGENCE AND DATA SCIENCE">B.TECH ARTIFICIAL INTELLIGENCE AND DATA SCIENCE</option>
                                            <option value="B.TECH BIO TECHNOLOGY">B.TECH BIO TECHNOLOGY</option>
                                            <option value="B.TECH COUMPUTER SCIENCE AND BUSINESS SYSTEMS">B.TECH COUMPUTER SCIENCE AND BUSINESS SYSTEMS</option>
                                            <option value="B.TECH AGRICULTURAL ENGINEERING">B.TECH AGRICULTURAL ENGINEERING</option>
                                         </select>
    <button className="btn btn-primary" style={{marginTop:20}} onClick={()=>fetchData()}>Filter</button>
  </div>







  <div style={{display:"flex",justifyContent:"space-between",gap:20,alignItems:"center"}}>

    <div style={{textAlign:"center",alignItems:"center",justifyContent:"center"}}> Application Status</div>
    <select name="preferredBranch2"  onChange={(e)=>{
            setFilter2(e.target.value)
        }} className="form-control" style={{ color: "black" }}>
        <option value="i">SELECT</option>   
                                            <option value="admitted">Admitted</option>
                                              <option value="pending">Visitted</option>
                                     </select>
    <button className="btn btn-primary" style={{marginTop:20}} onClick={()=>fetchData()}>Filter</button>
  </div>






  <div style={{display:"flex",justifyContent:"space-between",gap:20,alignItems:"center"}}>
  <div style={{textAlign:"center",alignItems:"center",justifyContent:"center"}}> State TN / AP</div>


  <select name="preferredBranch2"  onChange={(e)=>{
            setFilter4(e.target.value)
        }} className="form-control" style={{ color: "black" }}>
        <option value="">SELECT</option>   
        <option value="TN">TN</option>   
        <option value="AP">AP</option>   
                                              </select>


   <button className="btn btn-primary" style={{marginTop:20}} onClick={()=>fetchData()}>Filter</button>
  </div>




  <div style={{display:"flex",justifyContent:"space-between",gap:20,alignItems:"center"}}>
  <div style={{textAlign:"center",alignItems:"center",justifyContent:"center"}}> Qouta GQ / MQ</div>

   
  <select name="preferredBranch2"  onChange={(e)=>{
            setFilter5(e.target.value)
        }} className="form-control" style={{ color: "black" }}>
        <option value="">SELECT</option>   
        <option value="MQ">MQ</option>   
        <option value="GQ">GQ</option>   
                                              </select>


    <button className="btn btn-primary" style={{marginTop:20}} onClick={()=>fetchData()}>Filter</button>
  </div>

  <div style={{display:"flex",justifyContent:"space-between",gap:20,alignItems:"center"}}>
  <div style={{textAlign:"center",alignItems:"center",justifyContent:"center"}}> Application Number</div>

   <input type="text" placeholder="Application Number"  className="form-control" style={{width:"100%",padding:10,marginTop:20}} onChange={(e)=>setFilter3(e.target.value)} />
    <button className="btn btn-primary" style={{marginTop:20}} onClick={()=>fetchData()}>Filter</button>
  </div>


  <div style={{display:"flex",justifyContent:"space-between",gap:20,alignItems:"center"}}>
  <div style={{textAlign:"center",alignItems:"center",justifyContent:"center"}}> MobileNumber</div>

   <input type="text" placeholder="Search by PhoneNumber"  className="form-control" style={{width:"100%",padding:10,marginTop:20}} onChange={(e)=>setFilter1(e.target.value)} />
    <button className="btn btn-primary" style={{marginTop:20}} onClick={()=>fetchData()}>Filter</button>
  </div>


{
    detailedTable && data.length!=0  ?
    <div className="table-responsive">
    <table className="table table-striped" style={{color:"black"}}>
      <thead>
        <tr>
            {
                loader ? <h1>Loading...</h1> :



                Object.keys(data[0]).sort().map((key, index) => {
                    return (
                        <th key={index}>{key}</th>
                    );
                })
            }
    
        </tr>
      </thead>
      <tbody>
       
       {
        loader && data.length!=0  ? <h1>Loading...</h1> :

        data.slice().reverse().map((item, index) => {
                        return (
                            <tr key={index}>
                                {
                                    Object.keys(item).sort().map((key, index) => {
                                        return (
                                            <td key={index}>{item[key]}</td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })
       }
      </tbody>
    </table>
  </div>
  :

  <div className="table-responsive">
  <table className="table table-striped" style={{color:"black"}}>
    <thead>
      <tr>
         <th>Name of the Student </th>
         <th>Father / Guardian Name</th>
          <th>Mobile Number</th>
          {/* <th>Email</th> */}
          <th>Address</th>
          <th>Branch</th>
          {/* <th>Refered By</th> */}
          <th>Status</th>
          <th>Actions</th>

  
      </tr>
    </thead>
    <tbody>
     
     {
      loader && data.length!=0 ? <h1>Loading...</h1> :

                  data.slice().reverse().map((item, index) => {
                      return (
                          <tr key={index}>
                   
                               <td>{item.candidateName}</td>
                              <td>{item.guardianName}</td>
                              <td>{item.mobileNumber}</td>
                              {/* <td>{item.email}</td> */}
                              <td>{item.address}</td>
                              <td>{item.preferredBranch}</td>
                              {/* <td>{item.referenceName}</td> */}
                              <td>{item.status}</td>
                                <td style={{display:"flex",justifyContent:"center",gap:20}}> 
                                


{
    item.status === "Admitted" ?

    <button className="btn btn-primary"
                                    onClick={()=>{
                                        
                                       
                                           
                                        
                                        axios.put(`https://api.dremerz.net/api/InovationAdmission/${item.id}/`, 
                                            {
                                                status: "Pending"
                                            })
                                                .then(res => {
                                                    console.log(res);
                                                    alert("Updated Successfully");
                                                    fetchData();
                                                })
                                                .catch(err => {
                                                    console.log(err);
                                                    alert("Error Occured");
                                                })
                                     }
                                    }
                                        
                                    
                                    >Remove 
                                    
                                    </button>


:

<button className="btn btn-primary"
onClick={()=>{
    
   
       
    
    axios.put(`https://api.dremerz.net/api/InovationAdmission/${item.id}/`, 
    {
        status: "Admitted"
    })
            .then(res => {
                console.log(res);
                alert("Updated Successfully");
                fetchData();
            })
            .catch(err => {
                console.log(err);
                alert("Error Occured");
            })
 }
}
    

>Admit 

</button>

}

                                    
                                    <Link to={"/single-admission"} state={
                                        {
                                            data: item
                                        }
                                    } style={{color:"whitesmoke"}} > <button className="btn btn-primary"> Edit </button></Link>


                                    <Link to={"/fees-details"} state={
                                        {
                                            data: item
                                        }
                                    } style={{color:"whitesmoke"}} > <button className="btn btn-primary">Fees </button></Link>
  <Link to={"/admission-pdf"} state={
                                        {
                                            data: item
                                        }
                                    } style={{color:"whitesmoke"}} > <button className="btn btn-primary">Print </button></Link>

<Link to={"/admission-print"} state={
                                        {
                                            data: item
                                        }
                                    } style={{color:"whitesmoke"}} > <button className="btn btn-primary">Download </button></Link>

                                        <button
                                        onClick={() => {
                                            // Get confirmation from the user
                                            if (window.confirm("Are you sure you want to delete this record?")) {
                                            axios
                                                .delete(`https://api.dremerz.net/api/InovationAdmission/${item.id}/`)
                                                .then((res) => {
                                                console.log(res);
                                                alert("Deleted Successfully");
                                                fetchData();
                                                })
                                                .catch((err) => {
                                                console.log(err);
                                                alert("Error Occurred");
                                                });
                                            }
                                        }}
                                        className="btn btn-danger"
                                        >
                                        Delete
                                        </button>

                                </td>

                          </tr>
                      );
                  })
     }
    </tbody>
  </table>
</div>

}

     


     


    </div>
  </div>
</div>


</>
  )
}
