import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
  <ul className="nav">
    {/* <li className="nav-item">
      <Link className="nav-link" to={"/fees-details"}>
        <i className="mdi mdi-view-dashboard menu-icon" />
        <span className="menu-title"> Fees Details </span>
      </Link>
    </li>
   */}
  
    <li className="nav-item">
      <Link className="nav-link" to={"/admission-registration-form"}>
        <i className="mdi mdi-view-dashboard menu-icon" />
        <span className="menu-title">Admission Details</span>
      </Link>
    </li>
  
    
    <li className="nav-item">
      <Link className="nav-link" to={"/view-admission"}>
        <i className="mdi mdi-view-dashboard menu-icon" />
        <span className="menu-title">View Admission</span>
      </Link>
    </li>
  
    
 
 
   

  </ul>
</nav>

  )
}
