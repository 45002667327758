import React from 'react';

class ExcelExportButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonData: [
        { name: 'John', age: 30, city: 'New York' },
        { name: 'Jane', age: 25, city: 'Los Angeles' },
        { name: 'Doe', age: 40, city: 'Chicago' }
      ]
    };
  }

  handleExportToExcel = () => {
    // Convert JSON data to CSV format
    const csvData = this.convertJsonToCsv(this.state.jsonData);

    // Create a Blob object for the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };

  convertJsonToCsv = (jsonData) => {
    const header = Object.keys(jsonData[0]).join(',');
    const rows = jsonData.map((row) => Object.values(row).join(','));
    return header + '\n' + rows.join('\n');
  };

  render() {
    return (
      <div>
        <button onClick={this.handleExportToExcel}>Export to Excel</button>
      </div>
    );
  }
}

export default ExcelExportButton;
